import React, { FC, PropsWithChildren, PropsWithRef, Ref } from 'react'

import { Checkbox as CheckboxChakra } from '@chakra-ui/react'

export type CheckboxPropsStyle = {
    fontSize?: string | number
    isDisabled?: boolean
    isInvalid?: boolean
}

const Checkbox: FC<PropsWithChildren<PropsWithRef<CheckboxPropsStyle>>> = React.forwardRef(
    (props, ref: Ref<HTMLInputElement>) => {
        const { fontSize, ...rest } = props

        return (
            <CheckboxChakra
                ref={ref}
                fontSize={fontSize}
                w="100%"
                padding={2}
                color="indigo.500"
                colorScheme="green"
                variant="outline"
                {...rest}
            />
        )
    }
)

Checkbox.displayName = 'Checkbox'

export default Checkbox
