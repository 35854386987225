import React, { FC, HTMLInputTypeAttribute, PropsWithRef, Ref } from 'react'

import { Input as InputChakra } from '@chakra-ui/react'

export type InputProps = {
    type: HTMLInputTypeAttribute
    as?: keyof JSX.IntrinsicElements
}
export type InputPropsStyle = {
    fontSize?: string | number
    type?: string
    placeholder?: string
    isDisabled?: boolean
    isInvalid?: boolean
}

const Input: FC<PropsWithRef<InputProps & InputPropsStyle>> = React.forwardRef((props, ref: Ref<HTMLInputElement>) => {
    const { fontSize, type, as = 'input', placeholder, ...rest } = props

    return (
        <InputChakra
            as={as}
            ref={ref}
            fontSize={fontSize}
            type={type}
            placeholder={placeholder}
            w="100%"
            borderRadius={0}
            padding={2}
            variant={'flushed'}
            _hover={{ bg: 'lavender' }}
            _focus={{ boxShadow: 'none', borderColor: 'green.500', borderBottomWidth: 2 }}
            _active={{ boxShadow: 'none', borderColor: 'green.500', borderBottomWidth: 2 }}
            {...rest}
        />
    )
})

Input.displayName = 'Input'

export default Input
