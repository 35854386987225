import React, { FC, PropsWithChildren, PropsWithRef, Ref } from 'react'

import { Textarea as TextareaChakra } from '@chakra-ui/react'

export type TextAreaStyle = {
    fontSize?: string | number
    placeholder?: string
    h?: number
    isDisabled?: boolean
    isInvalid?: boolean
}

const TextArea: FC<PropsWithChildren<PropsWithRef<React.HTMLAttributes<HTMLElement> & TextAreaStyle>>> =
    React.forwardRef((props, ref: Ref<HTMLTextAreaElement>) => {
        const { fontSize, ...rest } = props

        return (
            <TextareaChakra
                ref={ref}
                fontSize={fontSize}
                w="100%"
                borderRadius={5}
                padding={2}
                color="black"
                variant="outline"
                bg={'gery.10'}
                _focus={{ boxShadow: 'none', borderColor: 'green.500', borderBottomWidth: 2 }}
                _active={{ boxShadow: 'none', borderColor: 'green.500', borderBottomWidth: 2 }}
                {...rest}
            />
        )
    })

TextArea.displayName = 'TextArea'

export default TextArea
