import axiosInstance from '@helpers/api'

export const source = 'staffea.com'

export const extraContactFields = {
    message: '89a47216aed384dc4ae3030bcbf9400aafed5d63',
    company: '4d180f796e8401da06353e42b197e74a0f67634c',
    job_title: '573b95a923f9228c900a8cc27fba86c8aa876ead',
    source: '5c629efa1eeeaaf286ca1dfce22ee27fe630845f',
} as const

export type ExtraContactFieldsType =
    | typeof extraContactFields.message
    | typeof extraContactFields.company
    | typeof extraContactFields.job_title
    | typeof extraContactFields.source

type BalancesPreferencesType = {
    [key in keyof ExtraContactFieldsType]: string
}

export type ContactFormPayload = {
    name: string
    email: string
    marketing_status: MarketingDisplayType
    phone?: string
    first_name: string
    last_name: string
    message: string
    company: string
    job_title: string
    source: string
}

export type ContactPayload = {
    name: string
    email: string
    marketing_status: MarketingDisplayType
    phone?: string
    first_name: string
    last_name: string
    source: string
} & BalancesPreferencesType

export type UsePipedriveResponse = {
    postPipedrive: (body: ContactPayload) => Promise<ContactResponse>
}

export const marketingDisplay = {
    SUBSCRIBED: 'subscribed',
    NO_CONSENT: 'no_consent',
} as const

export type MarketingDisplayType = typeof marketingDisplay.SUBSCRIBED | typeof marketingDisplay.NO_CONSENT

export type ContactResponse = {
    success: boolean
    data: ContactPayload[]
}

/**
 *@link https://developers.pipedrive.com/docs/api/v1/Persons#addPerson
 */
export const postPipedrive = async (body: ContactPayload) => {
    const { data } = await axiosInstance.post('/persons', body)
    return data
}
