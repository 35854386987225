import React, { FC, PropsWithChildren, PropsWithRef, Ref } from 'react'

import { Select as SelectChakra } from '@chakra-ui/react'

export type SelectPropsStyle = {
    fontSize?: string | number
    type?: string
    placeholder?: string
    isDisabled?: boolean
    isInvalid?: boolean
}

const Select: FC<PropsWithChildren<PropsWithRef<React.HTMLAttributes<HTMLElement> & SelectPropsStyle>>> =
    React.forwardRef((props, ref: Ref<HTMLSelectElement>) => {
        const { fontSize, placeholder, ...rest } = props

        return (
            <SelectChakra
                ref={ref}
                fontSize={fontSize}
                placeholder={placeholder}
                w="100%"
                borderRadius={0}
                color="grey.400"
                variant="flushed"
                _hover={{ bg: 'lavender' }}
                _focus={{ boxShadow: 'none', borderColor: 'green.500', borderBottomWidth: 2 }}
                _active={{ boxShadow: 'none', borderColor: 'green.500', borderBottomWidth: 2 }}
                {...rest}
            />
        )
    })
Select.displayName = 'Select'

export default Select
