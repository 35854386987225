import * as React from 'react'

import { Box, Flex, Heading, Spinner, Text, useMediaQuery, useToast } from '@chakra-ui/react'
import Seo from '@components/Seo'
import Section from '@components/core/Section'
import { CtaButton, OutlinedButton } from '@components/ui/Button'
import Checkbox from '@components/ui/Input/Checkbox'
import Input from '@components/ui/Input/Input'
import Select from '@components/ui/Input/Select'
import TextArea from '@components/ui/Input/TextArea'
import { graphql, navigate } from 'gatsby'
import { SubmitHandler, useForm } from 'react-hook-form'
import { FaCheckCircle as Ffacheckcircle } from 'react-icons/fa'
import { FaCircleXmark as Facirclexmark } from 'react-icons/fa6'
import { helmetJsonLdProp } from 'react-schemaorg'
import { Thing, WebSite } from 'schema-dts'

import {
    ContactFormPayload,
    ContactPayload,
    extraContactFields,
    marketingDisplay,
    postPipedrive,
    source,
} from '../../helpers/hookrequest'

const jsonLd = helmetJsonLdProp<Thing>({
    '@context': 'https://schema.org',
    '@id': 'https://www.staffea.com',
    '@type': 'Organization',
    name: 'Staffea',
    url: 'https://www.staffea.com',
    address: {
        '@type': 'PostalAddress',
        addressLocality: 'Colombelles',
        postalCode: '14460',
        streetAddress: '8 rue Léopold Sédar-Senghor',
        addressCountry: {
            '@type': 'Country',
            name: 'FR',
        },
    },
    contactPoint: {
        '@type': 'ContactPoint',
        contactType: 'customer service',
        availableLanguage: 'fr',
        email: 'contact@staffea.com',
    },
})

const jsonLdWebsite = helmetJsonLdProp<WebSite>({
    '@context': 'https://schema.org',
    '@type': 'WebSite',
    url: 'https://www.staffea.com',
    name: 'Staffea',
})

const Contact = () => {
    const [isDesktop] = useMediaQuery(['(min-width: 1200px)', '(min-width: 1600px)'])

    const toast = useToast()
    const [isLoading, setIsLoading] = React.useState(false)
    const [isDisabled, setIsDisabled] = React.useState(false)
    const [isSend, setIsSend] = React.useState(false)
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<ContactFormPayload>()

    const onSubmit: SubmitHandler<ContactFormPayload> = async (data) => {
        setIsLoading(true)
        setIsDisabled(true)
        try {
            const newContact = {
                name: data.last_name,
                email: data.email,
                marketing_status: data.marketing_status ? marketingDisplay.SUBSCRIBED : marketingDisplay.NO_CONSENT,
                phone: data.phone,
                first_name: data.first_name,
                last_name: data.last_name,
            } as ContactPayload

            newContact[extraContactFields['company']] = data.company
            newContact[extraContactFields['job_title']] = data.job_title
            newContact[extraContactFields['message']] = data.message
            newContact[extraContactFields['source']] = source

            const postResponse = await postPipedrive(newContact)

            if (postResponse) {
                toast({
                    duration: 5000,
                    render: () => (
                        <Box p={3} bg="green.500" borderRadius={10} color="white">
                            <Flex direction={'row'} align={'center'} gap={3}>
                                <Ffacheckcircle size={25} />

                                <Flex direction={'column'}>
                                    <p>Votre message a bien été envoyé !</p>

                                    <p>Nous revenons vers vous rapidement.</p>
                                </Flex>
                            </Flex>
                        </Box>
                    ),
                })
                setIsLoading(false)
                setIsSend(true)
            }
        } catch (e) {
            toast({
                duration: 5000,
                render: () => (
                    <Box p={3} bg="pink.500" borderRadius={10} color="white">
                        <Flex direction={'row'} align={'center'} gap={3}>
                            <Facirclexmark size={30} />

                            <Flex direction={'column'}>
                                <p>Votre message n’a pas pu être envoyé.</p>

                                <p>Réessayez ou bien contactez-nous directement par mail à contact@staffea.com.</p>
                            </Flex>
                        </Flex>
                    </Box>
                ),
            })
            setIsLoading(false)
            setIsDisabled(false)
            console.error('erreur', e)
        }
    }

    return (
        <>
            <Seo
                title="Logiciel Gestion de Planning"
                description="Découvrez Staffea pour la gestion de vos plannings ➜ ✓ Installation rapide ✓ Création en 2 heures ✓ Gestion en temps réel ➜ Demandez une démo !"
                script={[jsonLd, jsonLdWebsite]}
            />

            <main>
                <Section paddingY={10} bg="indigo.100" id="staffea-section" name="staffea">
                    <Flex
                        pt={14}
                        pb={20}
                        gap={8}
                        align="center"
                        justify={['center', 'center', 'space-between']}
                        wrap="wrap"
                        maxW="1200px"
                        mx="auto"
                    >
                        <Flex
                            direction="column"
                            align="center"
                            mx="auto"
                            sx={{
                                '@media (min-width: 1440px)': {
                                    maxW: '50%',
                                    alignItems: 'center',
                                },
                            }}
                        >
                            <Box
                                border="2px solid"
                                borderColor="lavender"
                                padding={'5'}
                                borderRadius={10}
                                minW={isDesktop ? 500 : 300}
                                minH={isDesktop ? 350 : 150}
                                display={'flex'}
                                alignItems={'center'}
                                justifyContent={'center'}
                            >
                                <Flex direction="column" align="center">
                                    <Heading
                                        as="h1"
                                        mb={10}
                                        fontWeight="bold"
                                        color="indigo.500"
                                        textAlign={['center', 'center', 'center']}
                                    >
                                        Nous contacter
                                    </Heading>

                                    {isSend ? (
                                        <Flex gap={5} flexDirection={'column'}>
                                            <Text color={'indigo.500'}>Merci de nous avoir contacté, à bientôt !</Text>

                                            <CtaButton
                                                text="Retourner à l'accueil"
                                                textButtonFontSize="14"
                                                onClick={() => navigate('../')}
                                            />
                                        </Flex>
                                    ) : (
                                        <form onSubmit={handleSubmit(onSubmit)}>
                                            <Flex direction={'column'} gap={5}>
                                                <Flex gap={10} direction={isDesktop ? 'row' : 'column'}>
                                                    <Input
                                                        {...register('last_name', { required: true })}
                                                        type="text"
                                                        placeholder="Nom"
                                                        isDisabled={isDisabled}
                                                        isInvalid={!!errors['last_name']}
                                                    />

                                                    <Input
                                                        {...register('first_name', { required: true })}
                                                        type="text"
                                                        placeholder="Prénom"
                                                        isDisabled={isDisabled}
                                                        isInvalid={!!errors['first_name']}
                                                    />
                                                </Flex>

                                                <Input
                                                    fontSize="m"
                                                    {...register('email', { required: true })}
                                                    type="email"
                                                    placeholder="Adresse mail"
                                                    isDisabled={isDisabled}
                                                    isInvalid={!!errors['email']}
                                                />

                                                <Input
                                                    {...register('phone', { required: false })}
                                                    type="number"
                                                    placeholder="Numéro de téléphone"
                                                    isDisabled={isDisabled}
                                                />

                                                <Flex gap={10} direction={isDesktop ? 'row' : 'column'}>
                                                    <Input
                                                        {...register('company', { required: true })}
                                                        type="text"
                                                        placeholder="Société"
                                                        isDisabled={isDisabled}
                                                        isInvalid={!!errors['company']}
                                                    />

                                                    <Select
                                                        {...register('job_title', { required: true })}
                                                        placeholder="Intitulé du poste"
                                                        isDisabled={isDisabled}
                                                        isInvalid={!!errors['job_title']}
                                                    >
                                                        <option value="Direction des soins">Direction des Soins</option>

                                                        <option value="Direction Logistique">
                                                            Direction Logistique
                                                        </option>

                                                        <option value="Direction des Opérations">
                                                            Direction des Opérations
                                                        </option>

                                                        <option value="Direction Ressources Humaines">
                                                            Direction Ressources Humaines
                                                        </option>

                                                        <option value="Direction Financière">
                                                            Direction Financière
                                                        </option>

                                                        <option value="Direction Générale">Direction Générale</option>

                                                        <option value="Autre">Autre</option>
                                                    </Select>
                                                </Flex>

                                                <TextArea
                                                    {...register('message', { required: false })}
                                                    placeholder="Message"
                                                    h={150}
                                                    isDisabled={isDisabled}
                                                />

                                                <Checkbox
                                                    {...register('marketing_status')}
                                                    isDisabled={isDisabled}
                                                    isInvalid={!!errors['marketing_status']}
                                                >
                                                    &quot;J&apos;accepte de recevoir des e-mails et/ou des SMS, étant
                                                    entendu que je peux facilement me désinscrire de ces communications
                                                    à tout moment après mon inscription&quot;
                                                </Checkbox>

                                                <OutlinedButton
                                                    type="submit"
                                                    text="Envoyer"
                                                    fontSize="14"
                                                    isLoading={isLoading}
                                                    spinner={<Spinner />}
                                                    isDisabled={isDisabled}
                                                />
                                            </Flex>
                                        </form>
                                    )}
                                </Flex>
                            </Box>
                        </Flex>
                    </Flex>
                </Section>
            </main>
        </>
    )
}

export default Contact

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { ns: { in: ["contact"] }, language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`
