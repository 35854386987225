import axios from 'axios'

const axiosInstance = axios.create({
    baseURL: 'https://api.pipedrive.com/v1',
    withCredentials: false,
    headers: {
        'x-api-token': `${process.env.PIPEDRIVE_API_ACCESS}`,
    },
})

export default axiosInstance
